import { default as i18nModule } from "i18next";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import { initReactI18next } from "react-i18next";

import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";

const TRANSLATIONS = {
  en,
  fr,
  de,
  it,
  es,
};

const i18n = i18nModule;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handlePlurals = (key: string, value: any, options: any): string => {
  const pluralsMap = [value.zero ?? value.one, value.one, value.two ?? value.other];
  if (Boolean(options.count) || options.count === 0) {
    const count: number = Number.parseInt(options.count);
    if (count < 3) return pluralsMap[count];
    else if (count < 10) return value.few ?? value.other;
    else return value.other;
  }
  return value.toString();
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: "en",
  fallbackLng: "en",
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
  returnedObjectHandler: handlePlurals,
});

export const addTranslation = (langKey: string, translation: object): void => {
  const localTranslation = get(TRANSLATIONS, langKey, {});
  const combined = merge(localTranslation, translation);

  if (!isEmpty(combined)) {
    i18n.addResourceBundle(langKey, "translation", combined, true, true);
  }
};

export const addTranslations = (translations: object): void => {
  Object.entries(translations).forEach(([lang, translation]) => addTranslation(lang, translation));
};
